import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { popover } from './assets/popover'
import { tooltip } from './assets/tooltip'
import { VueCookieNext } from 'vue-cookie-next'

createApp(App)
    .use(router)
    .use(VueCookieNext)
    .directive('popover', popover)
    .directive('tooltip', tooltip)
    .mount('#app')

import '@/assets/mobiscroll/css/mobiscroll.vue.min.css'
import '../public/css/style.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
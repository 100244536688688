<template>
    <footer class="login-footer">
        <ul class="list-unstyled d-flex">
            <li class="me-3"><a href="https://www.mapauthority.com/privacy" target="_blank">Privacy Policy</a></li>
            <li class="me-3"><a href="https://www.mapauthority.com/terms" target="_blank">Terms and Conditions</a></li>
            <li class="me-3"><a href="https://www.mapauthority.com/support" target="_blank">Support</a></li>
        </ul>
        <div>Copyright &copy; {{ new Date().getFullYear() }} MapAuthority</div>
    </footer>
</template>
